/** @jsx jsx */

import "../fonts/index.css"
import "what-input"

import { any } from "prop-types"
import { Fragment } from "react"
import { Global, jsx } from "theme-ui"

// Put providers here
export const wrapRootElement = ({ element }) => {
  return (
    /* eslint-disable react/jsx-fragments */
    <Fragment>
      <Global
        styles={{
          ":root": {
            scrollBehavior: "smooth",
            minWidth: "320px",
          },
          '[data-whatintent="mouse"] *:focus': {
            outline: "none",
          },
          "*": {
            boxSizing: "border-box",
          },
          body: {
            margin: 0,
            backgroundColor: "#f9f5f4",
          },
          a: {
            textDecoration: "none",
            "&:visited": {
              textDecoration: "none",
            },
          },
        }}
      />
      {element}
    </Fragment>
  )
}

wrapRootElement.propTypes = {
  element: any.isRequired,
}

export function onPreRenderHTML({ getHeadComponents, replaceHeadComponents }) {
  const headComponents = getHeadComponents()
  headComponents.push(
    <script
      async
      src="https://www.googletagmanager.com/gtag/js?id=UA-6100260-4"
    />,
    <script
      dangerouslySetInnerHTML={{
        __html: `
          window.dataLayer = window.dataLayer || [];
          function gtag(){dataLayer.push(arguments);}
          gtag('js', new Date());
          gtag('config', 'UA-6100260-4');
        `,
      }}
    />
  )

  replaceHeadComponents(headComponents)
}
