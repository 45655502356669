const theme = {
  colors: {
    text: "#4a4a4a",
    black: "#000",
    white: "#fff",
    backgroundSecondary: "#fafafa",
    primary: "#ea2d16",
    secondary: "#e26030",
  },
  space: [0, 4, 8, 12, 16, 20, 24, 30, 50],
  radii: [5, 15, 25],
  breakpoints: ["768px", "1140px", "1440px"],
  fonts: {
    heading: "GothamPro, sans-serif",
    body: "GothamNarrow, sans-serif",
  },
  fontWeights: {
    body: 300,
    heading: 900,
  },
  lineHeights: {
    body: 1.5,
    heading: 1.44,
  },
  fontSizes: [12, 14, 16, 18, 24, 30, 36, 48, 64, 72],
  shadows: {
    standard: "0 2px 12px 0 rgba(0, 0, 0, 0.1)",
    hover: "0 2px 20px 1px rgba(0, 0, 0, 0.2)",
  },
  grids: {
    base: {
      display: "grid",
      gridGap: [7],
    },
    cards: {
      variant: "grids.base",
      gridTemplateColumns: ["repeat(auto-fit, minmax(280px, 1fr))"],
    },
  },
  text: {
    heading: {
      color: "inherit",
      fontFamily: "heading",
      lineHeight: "heading",
      fontWeight: "heading",
      m: 0,
    },
    body: {
      color: "inherit",
      fontFamily: "body",
      fontWeight: "body",
      lineHeight: "body",
      m: 0,
    },
  },
  links: {
    primary: {
      backgroundColor: "#ea2d16",
      color: "#ffffff",
    },
    secondary: {
      border: "solid 3px #ea2d16",
      backgroundColor: "#ffffff",
      color: "#ea2d16",
    },
  },
  tags: {
    primary: {
      backgroundColor: "#feefed",
      color: "#ea2d16",
    },
    secondary: {
      backgroundColor: "#eaf4eb",
      color: "#149a26",
    },
    tertiary: {
      backgroundColor: "#e7eef9",
      color: "#166bea",
    },
  },
  styles: {
    root: {
      fontFamily: "body",
      fontWeight: "body",
      lineHeight: "body",
    },
    h1: {
      variant: "text.heading",
      fontSize: [5, 6, null, 7],
    },
    h2: {
      variant: "text.heading",
      letterSpacing: "1.1px",
      fontSize: [4, 5],
    },
    h3: {
      variant: "text.heading",
      letterSpacing: "0.8px",
      fontSize: [2, 3, null, 4],
    },
    h4: {
      variant: "text.heading",
      letterSpacing: "0.55px",
      fontSize: [2, 3],
    },
    p: {
      variant: "text.body",
      fontSize: 2,
    },
  },
}

export default theme
